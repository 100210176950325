<script setup lang="ts">
import SnackbarCenter from '~/components/layout/snackbarCenter.vue'
import VProgressBar from '~/components/ui/VProgressBar.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'

const router = useRouter()
const { getMediaUrl } = useGetMediaUrl()

const { path: curatorOnboardingFlowPath, steps: curatorOnboardingFlowSteps } =
  storeToRefs(useInfluencerOnboardingFlowStore())
const { activeStep: ACTIVE_STEP, COMPLETION_PERCENTAGE } = storeToRefs(
  useInfluencerOnboardingFlowStore(),
)

const redirectionTo = computed(() => {
  if (
    router.currentRoute.value.fullPath.includes(
      curatorOnboardingFlowPath.value,
    ) &&
    curatorOnboardingFlowSteps.value.some((stepName) =>
      router.currentRoute.value.fullPath.includes(stepName),
    )
  )
    return ''
  return '/'
})

const { CHECK_ACTIVE_STEP, GO_TO_PREVIOUS_STEP, REMOVE_STEP } =
  useInfluencerOnboardingFlowStore()

CHECK_ACTIVE_STEP(router.currentRoute.value.path)
</script>

<template>
  <div>
    <SnackbarCenter class="inLayoutIgnoreFontUpscale ignoreFontUpscale" />
    <div class="navIndexWrapper inLayoutIgnoreFontUpscale ignoreFontUpscale">
      <div
        class="mainParentContainer tw-border-red tw-h-15 tw-py-[18px] md:tw-h-[80px]"
      >
        <div
          class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between"
        >
          <span class="tw-block lg:tw-hidden">
            <i
              v-if="ACTIVE_STEP > 0"
              class="fas fa-chevron-left tw-cursor-pointer tw-text-base tw-text-discrete2"
              @click="GO_TO_PREVIOUS_STEP"
            />
          </span>
          <NuxtLinkLocale class="navLogo tw-shrink-0" :to="redirectionTo">
            <img
              :src="getMediaUrl('GrooverLogoWithText-Primary.svg')"
              width="136px"
              alt="Groover logo"
            />
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
    <VProgressBar :completion="COMPLETION_PERCENTAGE" />
    <slot />
  </div>
</template>

<style scoped lang="scss">
.navIndexWrapper {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  box-shadow: var(--shadow-regular);
  z-index: 10001;
}
</style>
